<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updatingItem.name }}
      <v-spacer></v-spacer>
      <v-btn
        color="success darken-1"
        text
        @click="add"
        :disabled="isDisabledBtnAdd"
      >
        {{ $t("labels.add") }}
      </v-btn>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>Chọn các ĐVVC con theo kho</v-card-text>

    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th style="width: 150px" class="text-center">
                {{ $t("labels.warehouse") }}
              </th>
              <th class="text-center">{{ $t("labels.delivery_company") }}</th>
              <th style="width: 90px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="`pn_${item.id}`">
              <td>
                <v-autocomplete
                  v-model="item.id_warehouse"
                  :items="warehouses"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  v-model="item.id_delivery_company"
                  :items="deliveries"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td class="text-center">
                <v-btn
                  v-if="!item.editing"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="save(item)"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "DialogDeliveryGroup",
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    deliveries: [],
    warehouses: [],
    isLoading: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  mounted() {
    this.getAllDelivery();
    this.getAllWarehouse();
    this.getList();
  },
  methods: {
    async getList() {
      const { data } = await httpClient.post(
        "/delivery/v1/get-handover-children",
        { id_delivery_company_group: this.updatingItem.id }
      );
      this.items = [...data];
    },
    async getAllWarehouse() {
      const { data } = await httpClient.post("/warehouse/v1/get-all");
      this.warehouses = data.map((e) => ({
        value: e.id,
        text: `${e.code_name || ""} ${!e.active ? "(inactive)" : ""}`,
      }));
    },
    async getAllDelivery() {
      const { data } = await httpClient.post("/common/v1/get-delivery-company");
      this.deliveries = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    add() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_delivery_company: null,
        editing: true,
      });
    },
    async save(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/delivery/v1/update-handover-children", {
          ...item,
          id_delivery_company_group: this.updatingItem.id,
        });
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
